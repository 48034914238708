import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'

import HomePage from 'pages/Home'
import ExplorePage from 'pages/Explore'
import FeedbackPage from 'pages/Feedback'
import TermsPage from 'pages/Terms'
import PrivacyPage from 'pages/Privacy'
import LicensesPage from 'pages/Licenses'

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/terms-of-service" element={<TermsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/licenses" element={<LicensesPage />} />

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
  )
}

export default App
